<template>
    <div class="holder">
        <div class="mt-3 mb-3">
            <span style="cursor: pointer;" @click="goBack()" class="mr-2"><i class="fa fa-arrow-left"></i></span>
            <span style="cursor: pointer;" @click="goBack()">Back</span>
        </div>

        <div class="opportunity-holder">
            <h5 class="mb-4">Opportunity desk</h5>
            <div class="opp-category-holder">
                <div class="opportunity-buttons" v-for="category in oppCategories" :key="category.id">
                    <button @click="navigateToMoreOpp(category.id)" class="bth" :class="[oppid == category.id ? 'activeOppBtn' : '']">{{category.type}}</button>
                </div>
            </div>

            <div class="">
                <div v-if="loadingProperty" class="m-auto text-center mt-4">
                    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                </div>
                
                <div v-else class="opportunity-body mt-3" v-for="item in opportunityBasedOnCategories" :key="item.id">
                    <h6>{{ item.title }}</h6>
                    <p>{{ item.specific_data.description }}</p>
                    <div class="d-flex justify-content-between align-items-center mt-4 amount-explore">
                        <h5><span>{{ Number(item.amount).toLocaleString() || 0.00 }}</span><span v-if="item.type == 'To Let' || item.type == 'For Sale'">/Annually</span></h5>
                        <button class="btn" @click="moveTodetails(item.id)">
                            Explore
                        </button>
                    </div>
                </div>
                <div class="empty text-center mt-4 " v-if="opportunityBasedOnCategories.length == 0 && !loadingProperty">No Record</div>
            </div>
        </div>
    </div>
</template>

<script>
    import opportunities from '../../services/opportunityDesk';
    import Loader from '../../components/Loader';
    export default {
        name: 'opportunityDeskView',
        component: {
            Loader
            // Loader: () => import('../../components/Loader'),
        },
        data() {
            return {
                loadingProperty: false,
                oppCategories: [],
                opportunityBasedOnCategories: [],
                oppid: "",
            }
        },
        computed: {
            
        },
        created() {
            
        },
        methods: {
            goBack() {
                this.$router.go(-1);
            },
            navigateToMoreOpp(id) {
                this.$router.push({path: `/user/opportunity-more/${id}`});
                this.oppid = id;
                this.opportunityByCategory();
            },
            moveTodetails(id) {
                this.$router.push({path: `/user/opportunity-property/${id}`});
                this.oppid = id;
                this.opportunityByCategory();
            },
            categoriesOpportunity() {
                this.loadingProperty = true;
                opportunities.opportunityCategory().then(data => {
                    // console.log("categories:", data);
                    this.loadingProperty = false;
                    if (data.success) {
                        this.oppCategories = data.data;
                        this.opportunityByCategory();
                    } else {
                        this.$toast.error(data.error);
                    }
                }).catch((err) => {
                    this.loadingProperty = false;
                    console.log(err);
                    // this.$toast.error("please check your network and refresh the page");
                });
            },
            async opportunityByCategory() {
                this.loadingProperty = true;
                this.oppid = this.$route.params.id;
               await opportunities.opportunityDeskByCategoryId(this.oppid).then(data => {
                    // console.log("Opportunitiescategories:", data);
                    this.loadingProperty = false;
                    if (data.success) {
                        this.opportunityBasedOnCategories = data.data;
                    } else {
                        this.$toast.error(data.error);
                    }
                }).catch((err) => {
                    this.loadingProperty = false;
                    console.log(err);
                    // this.$toast.error("please check your network and refresh the page");
                });
            },
        },
        mounted() {
            this.categoriesOpportunity();
        },
    }

</script>

<style lang="scss" scoped>
$primary: #0033ea;
$white: #ffffff;
$yellow: #ffb100;
$asheGrey: #555C74;
$offblack: #2B3352;

.opportunity-holder {
    border: 2px solid #EEF3FA;
    background: $white;
    padding: 24px 16px 16px;
}
.opp-category-holder {
    // display: ruby-text;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}
.opportunity-buttons {
    // display: flex;
    // flex-direction: row;
    // gap: 10px;
    button {
        border: 1px solid #A1A2A3;
        background-color: transparent;
        color: $asheGrey;
        font-family: Lato;
        font-size: 14px;
        font-weight: 500;
        line-height: 17.5px;
        text-align: center;
        height: 40px;
        // width: fit-content;
        border-radius: 50px;
        padding: 10px 12px;
        // margin-right: 10px !important;
        margin-bottom: 10px;
    }
    button:last-child {
        margin-right: 0;
    }
    button:hover {
        background: $primary;
        color: $white;
        border: none;
    }
    a {
        margin-right: 10px !important;
    }
    a:last-child {
        margin-right: 0 !important;
    }
}
.activeOppBtn {
    background: $primary !important;
    color: $white !important;
    border: none !important;
}

.opportunity-body {
    border-bottom: 1px solid #EBECEC;
    padding-bottom: 10px;
    h6 {
        font-family: Lato;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        text-align: justified !important;
        color: $offblack;
        margin: 10px 0 5px;
            /* direction: rtl; */
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    p {
        font-family: Lato;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px;
        text-align: left;
        color: $asheGrey;
    }
}
.opportunity-body:last-child {
    border-bottom: 0;
}

.amount-explore {
    h5 {
        font-family: Lato;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: $primary;
        // margin: 10px 0 5px;
        span {
            font-weight: 600;
        }
    }
    button {
        background: $primary;
        color: $white;
        font-family: Lato;
        font-size: 16px;
        font-weight: 500;
    }
}

@media screen and (max-width: 599px) {
    .opportunity-body {
        h6 {
            width: 300px;
        }
    }
}


// Loader starts
.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid var(--primary-color);
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--primary-color) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
// Loader ends
</style>